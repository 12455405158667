import { GamePluginType, type PluginBaseProps } from "@shared/utils/plugins";

import { GameElementCategory } from "@shared/game-engine";

import { createPluginComponent } from "../utils/createPluginComponent";

export const PlaceholderComponent = createPluginComponent<
  PluginBaseProps<void>
>(
  () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          textAlign: "center",
        }}
      ></div>
    );
  },
  {
    type: GamePluginType.GAME_COMPONENT,
    category: GameElementCategory.STATIC,
    name: "Placeholder",
    description: "A placeholder component",
    author: "none",
    version: 0,
    thumbnail: "",
    assets: [],
    schema: {
      type: "object",
    },
    features: {},
    templates: [],
  }
);
