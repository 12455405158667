import { type GameAction, GameCommand } from "@shared/game-engine";

/**
 * Frontend action executor
 *
 * @param action
 * @param payload
 */
export function executeAction(action: GameAction) {
  switch (action.command) {
    case GameCommand.VISIT_URL:
      window.open(action.payload, "_blank");
      break;
  }
}
