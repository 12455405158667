import { useState, useEffect, useCallback, useMemo } from "react";
import DocumentMeta from "react-document-meta";
import { useFirebase } from "@app/Firebase";

import {
  DEFAULT_THEME,
  type GameDataInterface,
  type GameEventType,
} from "@shared/game-engine";
import { type GameDefinitionDto, PlayerApiClient } from "@shared/api-client";
import {
  GamePlayerOffline,
  RequireInteractionBoundary,
  FullScreenBoundary,
} from "@shared/game-player";
import { deobfuscate } from "@shared/utils/obfuscator";
import UfoPildImg from "@shared/branding/theufolab/ufo-pild.png";
import RocketGif from "@shared/branding/theufolab/rocket.gif";

// Irrelevant events for analytics
const SKIP_EVENTS: (keyof GameEventType)[] = [
  "gameStateUpdated",
  "gameLogUpdated",
  "gameActionEnqueued",
];

const apiClient = new PlayerApiClient(import.meta.env.VITE_BACKEND_BASEURL);

function UfoPild() {
  return (
    <a href="https://theufolab.com" target="_blank">
      <img
        src={UfoPildImg}
        alt="Powered by UFOLab"
        title="Powered by UFOLab"
        style={{
          position: "absolute",
          bottom: 5,
          left: 5,
          zIndex: 1000,
          opacity: 0.5,
          height: "3vh",
          minHeight: "30px",
        }}
      />
    </a>
  );
}

function Player() {
  const { logEvent } = useFirebase();
  const [uuid, setUuid] = useState<string>();
  const [error, setError] = useState<Error | null>();
  const [game, setGame] = useState<GameDefinitionDto | null>();
  const [gameData, setGameData] = useState<GameDataInterface | null>();

  const [ready, setReady] = useState(false);
  const [scale, setScale] = useState(1);

  const loadGame = useCallback(async (uuid: string) => {
    apiClient
      .get({ path: "/g/:uuid", params: { uuid } })
      .then(async (game) => {
        setGame(game);

        const gameData = await apiClient.get({
          path: "/g/:uuid/data",
          params: { uuid },
        });

        const parsedGameData = JSON.parse(
          deobfuscate(gameData.d, uuid)
        ) as GameDataInterface;

        setGameData(parsedGameData);

        // Apply theme
        // TODO: Use a component
        document.body.style.backgroundColor =
          parsedGameData.theme?.background ||
          DEFAULT_THEME.background ||
          "#FFFFFF";

        document.body.style.color =
          parsedGameData.theme?.text || DEFAULT_THEME.text || "#000000";
      })
      .catch(setError);
  }, []);

  /* Handle window resize */
  useEffect(() => {
    const updateScale = () => {
      if (!gameData?.screen) return;

      const scale = Math.min(
        window.innerHeight / gameData.screen.height,
        window.innerWidth / gameData.screen.width
      );

      setScale(() => scale);
    };

    updateScale();

    window.addEventListener("resize", updateScale);
    window.addEventListener("orientationchange", updateScale);
    return () => {
      window.removeEventListener("resize", updateScale);
      window.removeEventListener("orientationchange", updateScale);
    };
  }, [gameData?.screen, ready]);

  // Initial load
  useEffect(() => {
    const uuid = window.location.pathname.split("/")[1];
    setUuid(uuid);
  }, []);

  // Game load
  useEffect(() => {
    if (uuid) {
      loadGame(uuid);
    } else {
      // setError(new Error("Game not found"));
    }
  }, [uuid, loadGame]);

  const onEventHandler = useCallback(
    (event: string, data: Record<string, unknown>) => {
      if (SKIP_EVENTS.includes(event as keyof GameEventType)) {
        return;
      }

      const filteredData = Object.keys(data).reduce<Record<string, unknown>>(
        (acc, key) => {
          // Filter out non-serializable data
          if (["string", "number", "boolean"].includes(typeof data[key])) {
            return { ...acc, [key]: data[key] };
          }
          return acc;
        },
        {}
      );

      logEvent?.(event, { game_uuid: uuid, ...filteredData });
    },
    [logEvent, uuid]
  );

  const playerInnerStyles = useMemo(
    () => ({
      width: gameData?.screen?.width,
      height: gameData?.screen?.height,
      transform: `translate(-50%, -50%) scale(${scale})`,
    }),
    [gameData?.screen?.width, gameData?.screen?.height, scale]
  );

  const playerSettings = useMemo(
    () => ({
      assetBaseUrl: game?.assetBaseUrl || "/",
      pluginBaseUrl: import.meta.env.VITE_PLUGIN_BASE_URL,
      builtinPluginBaseUrl: import.meta.env.VITE_PLUGIN_BUILTIN_BASE_URL,
      pluginIndexFile: import.meta.env.VITE_PLUGIN_INDEX_FILE,
    }),
    [game]
  );

  if (error) {
    throw error;
  }

  const Loading = useMemo(() => {
    return (
      <div className={`ufo-page-loading ${gameData ? "out" : ""}`}>
        <img src={RocketGif} alt="Loading..." />
      </div>
    );
  }, [gameData]);

  if (!game || !gameData) {
    return Loading;
  }

  const pageTitle = `TheUFOLab ${game.name ? `| ${game.name}` : ""}`;

  return (
    <DocumentMeta
      title={pageTitle}
      description={game.description}
      meta={{ "application-name": pageTitle }}
    >
      {Loading}
      <FullScreenBoundary
        requireFullScreen={gameData.screen?.fullScreen}
        requireOrientation={gameData.screen?.orientation}
        fullScreenWarning={(doFullScreen) => (
          <div className="ufo-full-screen-warning" onClick={doFullScreen}>
            <p>Click to enter fullscreen</p>
            <div className="icon" />
          </div>
        )}
        orientationWarning={
          <div className="ufo-full-screen-warning">
            <p>Please rotate your device</p>
            <div className="icon" />
          </div>
        }
      >
        <div className="ufo-game-player" draggable={false}>
          <div className="ufo-game-player-inner" style={playerInnerStyles}>
            <RequireInteractionBoundary
              enabled={false} // Disable the warning for now
              warning={(play) => (
                <div
                  className="ufo-full-screen-warning"
                  onClick={() => {
                    setReady(true);
                    play();
                  }}
                >
                  Click to start
                </div>
              )}
            >
              <GamePlayerOffline
                gameData={gameData}
                settings={playerSettings}
                onEvent={onEventHandler}
              />
            </RequireInteractionBoundary>
          </div>
        </div>
      </FullScreenBoundary>
      <UfoPild />
    </DocumentMeta>
  );
}

export default Player;
