import {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  type CSSProperties,
} from "react";

import {
  type GameTheme,
  type GameLevelRendered,
  type GameScreenSettings,
  type GameOverlayRendered,
  type GameStateRendered,
  type GameActionTrigger,
} from "@shared/game-engine";

import { StageElement } from "./components/StageElement";
import { Stage, StageLayer } from "./components/Stage";
import { transitionObjectToCSSAnimation } from "./utils/styles";

export interface GamePlayerProps {
  id?: string;
  editorMode?: boolean;
  screen: GameScreenSettings;
  theme: GameTheme;
  levelData?: GameLevelRendered;
  overlayData?: GameOverlayRendered;
  gameState?: GameStateRendered;
  style?: CSSProperties;
  show?: boolean;
  noTransitions?: boolean;
  onAction?: (
    id: string,
    trigger?: GameActionTrigger,
    payload?: string
  ) => void;
  onTransitionEnd?: () => void;
  debug?: boolean;
}

interface EnqueuedAction {
  id: string;
  trigger: GameActionTrigger;
  payload?: string;
}

export const GameCanvas = forwardRef<HTMLDivElement, GamePlayerProps>(function (
  {
    id,
    screen,
    levelData,
    overlayData,
    gameState,
    style,
    theme,
    editorMode,
    show,
    noTransitions,
    onAction,
    onTransitionEnd,
  },
  ref
) {
  const [actionQueue, setActionQueue] = useState<EnqueuedAction[]>([]);

  // Transitions styles
  const animationType = show ? "in" : "out";
  const transition = levelData?.transitions?.[animationType];
  const transitionsStyle =
    !noTransitions && transition
      ? transitionObjectToCSSAnimation(transition, animationType)
      : {};

  const levelStyle: CSSProperties = {
    ...transitionsStyle,
    ...levelData?.style,
  };

  const overlayStyle: CSSProperties = {
    ...overlayData?.style,
    pointerEvents: "none",
  };

  // Enqueue actions coming from stage elements
  const handleAction = useCallback(
    (id: string, trigger: GameActionTrigger, payload?: string) => {
      setActionQueue((prev) => [...prev, { id, trigger, payload }]);
    },
    []
  );

  // Handle transitions
  useEffect(() => {
    // TODO: Can I use forwardedRef here??
    const target = id && document.getElementById(id);
    if (target && transition && onTransitionEnd) {
      const checkTransitionEnd = (e: TransitionEvent) => {
        // Check only for stage transitions
        if (e.target === e.currentTarget) {
          onTransitionEnd();
        }
      };

      target.addEventListener("transitionend", checkTransitionEnd);
      const timeout = setTimeout(checkTransitionEnd, 5000); // MAX 5 seconds

      return () => {
        target.removeEventListener("transitionend", checkTransitionEnd);
        clearTimeout(timeout);
      };
    }
  }, [id, transition, onTransitionEnd]);

  // Process action queue, one by one
  useEffect(() => {
    if (actionQueue.length > 0) {
      const action = actionQueue[0];
      if (onAction) {
        onAction(action.id, action.trigger, action.payload);
      }

      setActionQueue((prev) => prev.slice(1));
    }
  }, [actionQueue, onAction]);

  return (
    <Stage id={id} screen={screen} theme={theme} ref={ref} style={style}>
      <StageLayer style={levelStyle}>
        {levelData?.layout.map((element) => (
          <StageElement
            key={element.id}
            element={element}
            gameState={gameState}
            onAction={handleAction}
            noTransitions={noTransitions}
            editorMode={editorMode}
          />
        ))}
      </StageLayer>
      <StageLayer style={overlayStyle}>
        {overlayData?.layout.map((element) => (
          <StageElement
            key={element.id}
            element={element}
            gameState={gameState}
            onAction={handleAction}
            noTransitions={noTransitions}
            editorMode={editorMode}
          />
        ))}
      </StageLayer>
    </Stage>
  );
});
